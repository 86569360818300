<template>
  <com-course-cont v-bind="$route.params" :course_id="course_id" :priex-route="priexRoute"></com-course-cont>
</template>

<script>
import comCourseCont from "@/views/api/teaching/course/courseCont";
export default {
  props:['priexRoute','course_id'],
  components: {comCourseCont}
}
</script>

<style scoped>

</style>